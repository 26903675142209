import React, {useState, useEffect} from 'react';
import {FaArrowCircleDown} from 'react-icons/fa';
import styled, {css} from 'styled-components';
import {ScrollButton} from '../DesignSystem/ScrollButton';
import { GoArrowDown } from "react-icons/go";
import { animateScroll as scroll,scroller } from 'react-scroll';

const ScrollToBottom = (props) => {
    const [stick, setStick] = useState(false)    
    // const onClickHandler = () => {
    //   scroll.scrollToBottom({
    //     duration: 500,
    //     delay: 0,
    //     smooth: 'easeInOutQuart'
    //   });
    // } 
    const onClickHandler = () => {
      scroller.scrollTo('footer', {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -1000
      });
    };
    // const onClickHandler = () => {
    //   window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    // }
    
    // useEffect(() => {
    //   var position = window.scrollY
    //   window.addEventListener('scroll', function(){
    //     let scrollPos = window.scrollY;
    //     if(scrollPos > 2000){
    //       setStick(false);
    //     }else if(scrollPos > position){
    //       setStick(true);
    //     }else{
    //       setStick(false);
    //     }
    //     position = scrollPos  
    //   });
    //   return () => {
    //       window.removeEventListener('scroll', function(){
    //         let scrollPos = window.scrollY;
    //         if(scrollPos > 7000){
    //           setStick(false);
    //         }else if(scrollPos > position){
    //           setStick(true);
    //         }else{
    //           setStick(false);
    //         }
    //         position = scrollPos  
    //       });
    //   }
      
    // });
        useEffect(() => {
          let position = window.scrollY;
        
          const handleScroll = () => {
            let scrollPos = window.scrollY;
            if (scrollPos > document.documentElement.scrollHeight - window.innerHeight - 2000) {
              setStick(false);
            } else if (scrollPos > position) {
              setStick(true);
            } else {
              setStick(false);
            }
            position = scrollPos;
          };
        
          window.addEventListener('scroll', handleScroll);
        
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);
 
    return (
        <ScrollButton type="button" onClick={onClickHandler} isStick={stick} {...props}>
          <GoArrowDown className="icon arrow-up" />
          <GoArrowDown className="icon arrow-bottom"/>
        </ScrollButton>
    )
}

export default ScrollToBottom
