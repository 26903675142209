import React from 'react';
// import { Provider } from 'react-redux';
// import { store, persistor } from './src/store'; // Adjust the path to your store
// import store from './src/store';
// import persistor  from './src/store';
// import { PersistGate } from 'redux-persist/integration/react';
import Layout from './src/components/layout';
import ReactDOM from "react-dom/client";
import { hydrateRoot } from 'react-dom/client';


import "@fontsource/open-sans"
import "@fontsource/unbounded"
import "@fontsource/montserrat"
import "@fontsource/epilogue"
import "@fontsource/quicksand"
import "@fontsource/syne"
// ErrorBoundary component to catch and handle errors
// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // Log error to an error reporting service
//     console.error("Uncaught error:", error, errorInfo);
//   }

//   render() {
//     if (this.state.hasError) {
//       // Render fallback UI
//       return <h1>Something went wrong.</h1>;
//     }

//     return this.props.children;
//   }
// }
// export const onClientEntry = () => {
//   const script = document.createElement("script");
//   script.type = "text/javascript";
//   script.async = true;
//   script.src = "https://www.clarity.ms/tag/nrrhvz8l5u?ref=bwt";
//   document.head.appendChild(script);
// };
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(


      element


    );
  }
}
// export const wrapRootElement = ({ element }) => (
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       {element}
//     </PersistGate>
//   </Provider>
// );
// export const replaceHydrateFunction = () => {
//     return (element, container) => {
//       const root = ReactDOM.createRoot(container)
//       root.render(element)
//     }
//   }





// export const wrapRootElement = ({ element }) => (
//   <Provider store={store}>
//     <PersistGate loading={null}  persistor={persistor}>
//       {element}
//     </PersistGate>
//   </Provider>
// );
export const wrapPageElement = ({ element }) => (

  <Layout >
    {element}
  </Layout>


);