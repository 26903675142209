import React from 'react'

import { ThemeProvider } from 'styled-components'
import Header from './Header';

import GlobalStyle from '../styles/GlobalStyles';
import ScrollToTop from "./ScrollButton/ScrollTopButton";
import ScrollToBottom from "./ScrollButton/ScrollBottomButton";
import theme from '../constants/theme';





export default function Layout({ children }) {
  return (
  
        
      <ThemeProvider theme={theme.light}>
      <GlobalStyle theme={theme.light}/>
        <Header/>
        <ScrollToTop />
        <ScrollToBottom />
          {children}
    
      </ThemeProvider>
  )
}