import styled from 'styled-components';

export const PopUpContainer = styled.div`
position: fixed;
z-index: 999;
top: 0px;
left: 0;
background: rgba(0,0,0,0.4);
/* backdrop-filter: blur(10px) saturate(109%); */
width: 100%;
height: 100%;
font-size: 100%;

@media screen and (max-width: 768px) {
  /* top: 8%; */
}

`;

export const PopUpContainerFlou = styled.div`
position: fixed;
z-index: 999;
top: 0px;
left: 0;
background: rgba(0,0,0,0.4);
backdrop-filter: blur(10px) saturate(20%);
width: 100%;
height: 100%;
font-size: 100%;
`;

export const PopUp = styled.div`
position: absolute;
transform: translate(-50%,-50%);
top: 40%;
left: 50%;
overflow: auto;
z-index: 9988888;
width: 500px;
z-index: ${props => props.zIndex};
/* background: rgb(255 255 255 / 98%); */
border-radius: 7px;
margin: 100px auto 0;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
gap:${props => props.gap};
border: 3px solid ${(props) => props.theme.bleuHP};
&.react-draggable {
  position: fixed;
  top: 20% !important;
  left: 28% !important;
  z-index: 998 !important;
  @media screen and (max-width: 768px) {
    left: 3% !important;
  }
}
&.form {
  width: 700px;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
}
@media screen and (max-width: 1270px) {
  &.prod {
    top: 710px;
  }
}
@media screen and (max-width: 1000px) {
  &.prod {
    top: 730px;
  }
}
@media screen and (max-width: 768px) {
  margin: 30px auto;
  width: 95%;
  max-width: 100%;
  &.prod {
    top: 80%;
  }
}
@media screen and (max-width: 600px) {
  &.prod {
    top: 85%;
  }
}
`;

export const PopUpMessagerie = styled.div`
position: absolute;
min-width: 340px;
bottom : ${props => props.bottom};
left:${props => props.left};
z-index: ${props => props.zIndex};
transform: translate(-50%,-50%);
width: auto;
background: #fff;
padding: 0;
border-radius: 7px;
margin: auto;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
border: 1px solid ${(props) => props.theme.bleuHP};
@media screen and (max-width: 992px) {
  width: 80%;
  max-width: 100%;
  bottom: 20%;
}
@media screen and (max-width: 768px) {
  width: 95%;
  max-width: 100%;
  bottom: 20%;
}
@media screen and (max-width: 310px) {
  bottom: 21%;
}
`;


export const PopUpTitle = styled.div`
/* line-height: unset !important; */
font: 600 20px Quicksand, sans-serif;
background-color: ${(props) => props.theme.bleuHP};
text-decoration: none;
border: none;
width: 100%;
padding: 8px 40px;
color: #fff; 
text-align: center;
text-transform: uppercase;
border-top-left-radius: 2px;
border-top-right-radius: 2px;
&.grab {
  cursor: grab;
}
`;

export const ButtonPop=styled.a`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
border-radius: 5px; 
border: none; 
background-color: transparent;
padding: 10px 20px;
cursor: pointer;
color: ${props => props.theme.bleuHP}; 
text-align: left; 
font: 500 17px Epilogue, sans-serif;
transition: all .2s ease-out; 
line-height: 1.2; 
&.center {
  justify-content: center;
}
&.auto {
  width: auto;
}
&.bckg {
  background-color: ${props => props.theme.bleuHP};
  color: #fff;
  border-radius: 0;
  &>svg{
    color:  #fff;
  }
}
&>svg{
  color: ${props => props.theme.bleuTextes};
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
&:hover {
  text-decoration: none;
  border-color: ${(props) => props.theme.rose};
  color: ${(props) => props.theme.rose};
  &>svg{
    color: ${props => props.theme.rose};
  }
}
&.yellowHover:hover {
  color: ${props => props.theme.jaune};
&>svg{
  color: ${props => props.theme.jaune};
}
}
`

export const ButFermer=styled.button`
position: absolute;
right: 2px;
top: 5px;
text-decoration: none;
border-radius: 5px; 
border: none; 
background-color: transparent;
cursor: pointer;
color: #fff;
text-align: left; 
font: 600 17px Epilogue, sans-serif;
transition: all .2s ease-out; 
z-index: 9999;
&.bleuHp {
  color: ${props => props.theme.bleuHP};
} 
&>svg{
  height: 25px;
  width: 25px;
}
&:hover {
  border-color: ${(props) => props.theme.rose};
  color: ${(props) => props.theme.rose};
}
`;

export const PopUpContent = styled.div`
color: ${(props) => props.theme.bleuHP};
padding: 0 20px;
`
export const Buttons = styled.div`
display: flex;
padding-bottom: 20px;
`

export const Wrap = styled.div`
max-height: calc(100vh - 150px);
box-sizing: border-box;
overflow: auto;
width: 100%;
display: flex;
flex-direction: column;
gap: 20px;
padding: 12px;
background: rgb(255 255 255);
&>a {
  margin: auto;
}
&.gap0 {
  gap: 0px;
}
`