import React from "react";
import styled from 'styled-components';
import {TbMessage2Question} from 'react-icons/tb'
import {TbCalendarStats} from 'react-icons/tb'
import {FaPlus} from 'react-icons/fa'
import {FaPhoneAlt} from 'react-icons/fa'
import {RiExchangeFundsLine} from 'react-icons/ri'

const BtnRose = styled.button`
display: ${props => props.display || "flex"};
margin: ${props => props.margin || "0"};
color: #fff;
text-align: left;
font: 600 20px Quicksand, sans-serif;
text-transform: uppercase;
padding: ${props => props.padding || "20px"};
text-align: center;
text-decoration: none;
cursor: pointer;
gap: 10px;
align-items: center;
justify-content: center;
flex-shrink: 0;
position: relative;
background: ${(props) => props.theme.rose};
transition: ${props => props.transition || "all .2s ease-out"};
border-radius: 5px;
border: none;
width: fit-content;
&.heroProduit {
text-transform: initial;
padding: 10px;
font-size: 16px;
}
&>svg{
  display: none;
  transition: ${props => props.transition || "all .2s ease-out"};    
}
&:hover {
    background: ${props => props.theme.bleuHP};
    color: #fff;
    box-shadow: inset 0 0 0 1px #fff, rgba(0, 0, 0, 0.25) 0px 8px 4px 0px;
    text-decoration: none;
}

&.question {
    &>svg.question {
      display: flex;
    }
}
&.rdv {
    &>svg.rdv {
      display: flex;
    }
}
&.plus {
    &>svg.plus {
      display: flex;
    }
}
&.phone {
    &>svg.phone {
      display: flex;
    }
}
&.vendre {
    &>svg.vendre {
      display: flex;
    }
}

@media only screen and (max-width: 768px) {
width: 100%;
max-width: 300px;
padding: 15px 10px;
font-size: 18px;
}
`;

export default function BoutonRoseHP({className,libellé, width, margin, padding, display,transition, onClick, url, target}) {
const handleClick = (event) => {
  event.stopPropagation();
  if (url !== undefined && url !== null && url !== "") {
    if (target) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }
  if (onClick) {
    onClick(event);
  }
};

    return <BtnRose margin={margin} onClick={handleClick} className={className} ><FaPhoneAlt className="phone"/><TbMessage2Question className="question"/><TbCalendarStats className="rdv"/><FaPlus className="plus"/><RiExchangeFundsLine className="vendre"/>{libellé}</BtnRose>      


}