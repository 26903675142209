import React from "react";
import styled from 'styled-components';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";

const Btn = styled.button`
text-decoration: none;
border: none;
background: transparent;
border: 1px solid ${(props) => props.theme.bleuTextes2};
border-radius: 5px; 
padding: 20px; 
display: flex; 
flex-direction: row; 
gap: 10px; 
align-items: center; 
justify-content: center;
width: ${props => props.width || "240px"};
position: relative;
cursor: pointer;
color: ${(props) => props.theme.bleuTextes2};
text-align: left; font: 600 17px Quicksand, sans-serif; 
text-transform: uppercase;
transition: all .2s ease-out;
&> svg {
  display: none;
  color: ${(props) => props.theme.bleuTextes2};
} 
&.search {
  &> svg.search {
    display: block;
  }
}  
&.check {
  &> svg.check {
    display: block;
  }
}
&.annuler {
  &> svg.annuler {
    display: block;
  }
}  
&.links {
  align-items: center;
  text-align: center;
  &> svg {
    display: none;
  }
}
  &:hover {
    background: rgba(0, 0, 0, 0.06);
    color: ${(props) => props.theme.bleuTextes2};
    text-decoration: none;
    &> svg {
      color: ${(props) => props.theme.bleuTextes2};
    }
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
}
`;


export default function BoutonJauneInverseHP(props) {
  const handleClick = () => {
    if (props.onClick) {
        props.onClick();
    }
    if (props.url) {
        window.location.href = props.url;
    }
};

    return <Btn className={props.className} width={props.width} widthMob={props.widthMob} onClick={handleClick}><AiOutlineClose className="annuler"/><FaSearch className="search"/><FaCheck className="check"/>{props.libellé}</Btn>   


}