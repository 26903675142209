const breakpoints = {
  xs: '576px', //<576px
  sm: '576px',//≥576px
  md: '768px',  //≥768px  
  lg: '992px', //≥992px
  xl: '1200px',//≥1200px
  xxl: '1400px',//≥1400px
}

export default breakpoints
