import styled from 'styled-components'
import { NavLink } from 'reactstrap'

import breakpoints from '../constants/breakpoints'

const HeaderLayout = styled.div`
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  //padding: 3rem 10rem;
  z-index: 999;
 


  @media (max-width: ${breakpoints.md}) {
   //padding: 2rem 5rem;
  }

  @media (max-width: ${breakpoints.sm}) {
    //padding: 1.5rem 2rem;
  }


`


export default HeaderLayout
